import { effect, inject, Signal } from '@angular/core';
import { PwDrawerService } from '@pwiz/infra/drawer';
import { Router } from '@angular/router';

export function useDrawerRoute<T>(
  $params: Signal<T>,
  open: (params: T) => void,
) {
  const drawer = inject(PwDrawerService);
  const router = inject(Router);

  effect(
    () => {
      const params = $params();
      if (params) {
        open(params);
      }
    },
    { allowSignalWrites: true },
  );

  effect(() => {
    const isOpen = drawer.$isOpen();
    if (!isOpen) {
      router.navigate([{ outlets: { sidebar: null } }], {
        queryParamsHandling: 'merge',
      });
    }
  });
}
